<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Gestión de Calidad</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Listado de Seguimientos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Listado de Seguimientos
          <Badge size="xlarge" severity="success">
            {{ totalRecords ?? 0 }}</Badge
          >
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO SEGUIMIENTO"
          v-if="'Paciente Crear' in auth.user.permissions"
          v-tooltip.top="'Nuevo Seguimiento'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2 p-button-lg"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtseguimientos"
        :value="seguimientos"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="20"
        :rowHover="true"
        class="p-datatable-sm p-datatable-gridlines"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Pacientes"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :lazy="true"
        :totalRecords="totalRecords"
        v-model:filters="buscar"
        :rowsPerPageOptions="[20, 500, 1000]"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        stripedRows
        showGridlines
        selectionMode="single"
        @row-dblclick="editSeguimiento($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column>
          <template #header>
            <i class="pi pi-cog" v.tooltip.top="'Acciones'"></i>
          </template>
          <template #body="slotProps">
            <Button
              class="p-button-rounded p-button-primary p-button-outlined p-button-sm"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
        <Column
          field="paciente.nombre"
          header="NOMBRE_PACIENTE"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          style="font-weight: bold"
        >
          <template #body="{ data }">
            <div v-if="data.paciente.imagen">
              <Image
                :src="'data:image/png;base64, ' + data.paciente.imagen"
                width="32"
                class="rounded-circle"
                alt="imagen"
                style="width: 32px; height: 32px; object-fit: cover"
                preview
              ></Image>
              <span class="ml-2 align-middle">{{ data.paciente.nombre }}</span>
            </div>
            <div v-else>
              {{ data.paciente.nombre }}
            </div>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre Completo..."
            />
          </template>
        </Column>

        <Column
          field="medico.nombre_completo"
          header="NOMBRE_MEDICO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre Completo..."
            />
          </template>
        </Column>
        <Column field="procedimiento" header="PROCEDIMIENTO" style="font-weight: bold"></Column>
        <Column
          field="fecha_cirugia"
          header="FECHA_CIRUGIA"
          style="text-align: center"
        >
          <template #body="{ data }">
            <span v-if="data.fecha_cirugia != null">
              {{ data.fecha_cirugia }}
            </span>
            <span v-else>
              <Badge size="small" severity="warning">Pendiente...</Badge>
            </span>
          </template>
        </Column>
        <Column
          field="nombre_sala_y_cama"
          header="SALA/CAMA"
          style="text-align: center"
        >
          <template #body="{ data }">
            <span v-if="data.nombre_sala_y_cama != null">
              {{ data.nombre_sala_y_cama }}
            </span>
            <Badge v-else size="small" severity="warning">Pendiente...</Badge>
          </template>
        </Column>
        <Column
          field="fecha_alta"
          header="FECHA_ALTA"
          style="text-align: center"
        >
          <template #body="{ data }">
            <span v-if="data.fecha_alta != null">
              {{ data.fecha_alta }}
            </span>
            <span v-else>
              <Badge size="small" severity="warning">Pendiente...</Badge>
            </span>
          </template>
        </Column>
        <Column
          field="fecha_retiro_puntos"
          header="RETIRO_PUNTOS"
          style="text-align: center"
        >
          <template #body="{ data }">
            <span v-if="data.fecha_retiro_puntos != null">
              {{ data.fecha_retiro_puntos }}
            </span>
            <span v-else>
              <Badge size="small" severity="warning">Pendiente...</Badge>
            </span>
          </template>
        </Column>
        <Column
          field="estado"
          header="ESTADO"
          style="text-align: center;"
        >
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <SeguimientoCreate
      :show="seguimientoDialog"
      :seguimiento="seguimiento"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarSeguimientos"
    >
    </SeguimientoCreate>
  </div>
</template>
    
  <script>
import { FilterMatchMode } from "primevue/api";
import SeguimientoService from "@/service/SeguimientoService";
import { useAuth } from "@/stores";
import SeguimientoCreate from "@/module/seguimientos/SeguimientoCreate.vue";

export default {
  components: {
    SeguimientoCreate,
  },
  data() {
    return {
      seguimientos: null,
      cargando: true,
      seguimientoDialog: false,
      seguimiento: {},
      enviado: false,
      errors: {},
      lazyParams: {},
      totalRecords: 0,
      claseEstado: [
        "status-outofstock",
        "status-lowstock",
        "status-outofstock",
        "customer-badge status-new",
        "status-instock",
      ],
      buscar: {
        "medico.nombre_completo": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "paciente.nombre": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  seguimientoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.seguimientoService = new SeguimientoService();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtseguimientos.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.cargarSeguimientos();
  },
  methods: {
    onPage(event) {
      this.lazyParams = event;
      this.cargarSeguimientos();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarSeguimientos();
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Paciente Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editSeguimiento(datos);
          },
        },
      ];
    },
    cargarSeguimientos() {
      this.cargando = true;
      setTimeout(() => {
        this.seguimientoService
          .getSeguimientos({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.seguimientos.total;
            this.seguimientos = data.seguimientos.data;
            this.cargando = false;
          });
      }, Math.random() * 1000 + 250);
    },
    openNuevo() {
      this.seguimiento = {
        estado: { label: "Pen. - Cirugía", value: 1 },
      };
      this.enviado = false;
      this.seguimientoDialog = true;
    },
    ocultarDialog() {
      this.seguimientoDialog = false;
      this.enviado = false;
    },
    editSeguimiento(seguimiento) {
      this.seguimiento = {
        ...seguimiento,
        estado: { label: seguimiento.estado_texto, value: seguimiento.estado },
      };
      this.seguimientoDialog = true;
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "Cancelado";
        case 1:
          return "Operado";
        case 2:
          return "Obito";
        case 3:
          return "Retiro-Puntos";
        case 4:
          return "Finalizado";
        default:
          break;
      }

      return "";
    },
  },
};
</script>
    
  <style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style> 