<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    :header="
      data_seguimiento.id
        ? 'Editar Seguimiento - PACIENTE: ' + data_seguimiento.nombre_paciente
        : 'Nuevo Seguimiento'
    "
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4" v-if="!data_seguimiento.id">
          <label for="paciente"
            ><strong>SELECCIONE PACIENTE: </strong>
            <span class="p-invalid">*</span></label
          >
          <div class="p-inputgroup">
            <Dropdown
              id="paciente"
              v-model="data_seguimiento.paciente"
              :options="pacientes"
              optionLabel="nombre"
              filter
              filterPlaceholder="Buscar Paciente"
              placeholder="Seleccione un paciente..."
            >
            </Dropdown>
            <small class="p-invalid" v-if="errors.paciente">{{
              errors.paciente[0]
            }}</small>
            <Button
              icon="pi pi-plus"
              class="p-button-primary"
              @click="openNuevo"
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <label for="medico"
            ><strong>SELECCIONE M&Eacute;DICO: </strong
            ><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="medico"
            v-model="data_seguimiento.medico"
            :options="medicos"
            optionLabel="nombre_completo"
            filter
            filterPlaceholder="Buscar Medico"
            placeholder="Seleccione un medico..."
            :disabled="data_seguimiento.id"
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.medico">{{
            errors.medico[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="procedimiento"
            ><strong>PROCEDIMIENTO: </strong
            ><span class="p-invalid">*</span></label
          >
          <Textarea
            id="procedimiento"
            v-model="data_seguimiento.procedimiento"
            :autoResize="true"
            :rows="1"
          ></Textarea>
          <small class="p-invalid" v-if="errors.procedimiento">{{
            errors.procedimiento[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="sala"
            ><strong>SALA / CAMA: </strong
            ><span> {{ data_seguimiento.sala_nombre ?? "-" }}</span></label
          >
          <Dropdown
            id="sala"
            v-model="data_seguimiento.cama_id"
            :options="camas"
            optionLabel="nombre"
            optionValue="id"
            filter
            filterPlaceholder="Buscar Sala"
            placeholder="Seleccione una sala..."
          >
            <template #option="slotProps">
              <span
                >{{ slotProps.option.sala_nombre }} -
                {{ slotProps.option.nombre }}</span
              >
            </template>
          </Dropdown>
          <small class="p-invalid" v-if="errors.sala">{{
            errors.sala[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="fecha_cirugia"
            ><strong>FECHA CIRUG&Iacute;A: </strong></label
          >
          <Calendar
            id="fecha_cirugia"
            v-model="data_seguimiento.fecha_cirugia"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            showTime
            :disabled="data_seguimiento.id"
          />
          <small class="p-invalid" v-if="errors.fecha_cirugia">{{
            errors.fecha_cirugia[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4" v-if="data_seguimiento.id">
          <label for="fecha_alta"><strong>FECHA ALTA: </strong></label>
          <Calendar
            id="fecha_alta"
            v-model="data_seguimiento.fecha_alta"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            showTime
          />
          <small class="p-invalid" v-if="errors.fecha_alta">{{
            errors.fecha_alta[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-4" v-if="data_seguimiento.id">
          <label for="fecha_retiro_puntos"
            ><strong>FECHA RETIRO PUNTOS: </strong></label
          >
          <Calendar
            id="fecha_retiro_puntos"
            v-model="data_seguimiento.fecha_retiro_puntos"
            :showIcon="true"
            dateFormat="dd/mm/yy"
            showTime
          />
          <small class="p-invalid" v-if="errors.fecha_retiro_puntos">{{
            errors.fecha_retiro_puntos[0]
          }}</small>
        </div>

        <div
          :class="
            data_seguimiento.id
              ? 'field col-12 md:col-8'
              : 'field col-12 md:col-4'
          "
        >
          <label for="observacion"><strong>OBSERVACIONES: </strong></label>
          <Textarea
            id="observacion"
            v-model="data_seguimiento.observacion"
            :autoResize="true"
            :rows="1"
          ></Textarea>
        </div>

        <div class="field col-12 md:col-4" v-if="data_seguimiento.id">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_seguimiento.estado"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="seguimiento.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="seguimiento.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="guardarSeguimiento"
        :loading="enviado"
      />
    </template>
    <PacienteCreate
      :show="pacienteDialog"
      :paciente="paciente"
      @closeModal="ocultalModalPaciente"
      @actualizarListado="cargarPacientes"
    >
    </PacienteCreate>
  </Dialog>
</template>
    
<script>
import SeguimientoService from "@/service/SeguimientoService";
import PacienteService from "@/service/PacienteService";
import PacienteCreate from "@/module/pacientes/PacienteCreate.vue";
export default {
  components: {
    PacienteCreate,
  },
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    seguimiento: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  seguimientoService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_seguimiento: this.seguimiento,
      enviado: false,
      estados: [
        { label: "Cancelado", value: 0 },
        { label: "Operado", value: 1 },
        { label: "Obito", value: 2 },
        { label: "Retiro de puntos", value: 3 },
        { label: "Finalizado", value: 4 },
      ],
      errors: {},
      claseEstado: [
        "status-outofstock",
        "status-lowstock",
        "status-outofstock",
        "customer-badge status-new",
        "status-instock",
      ],
      pacientes: [],
      medicos: [],
      camas: [],
      pacienteDialog: false,
      paciente: {},
    };
  },
  mounted() {
    this.seguimientoService.datos_necesarios_seguimiento().then((data) => {
      this.pacientes = data.pacientes;
      this.medicos = data.medicos;
      this.camas = data.camas;
    });
  },

  pacienteService: null,
  created() {
    this.seguimientoService = new SeguimientoService();
    this.pacienteService = new PacienteService();
  },
  methods: {
    cargarPacientes(paciente = null) {
      this.pacienteService
        .getPacientesAll()
        .then((response) => {
          this.pacientes = response;
          if (paciente) {
            this.pacientes.forEach((element) => {
              if (element.id == paciente.id) {
                this.data_seguimiento.paciente = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openNuevo() {
      this.paciente = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.pacienteDialog = true;
    },
    ocultalModalPaciente() {
      this.pacienteDialog = false;
      this.paciente = {};
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarSeguimiento() {
      this.errors = {};
      this.enviado = true;

      //valido que el paciente y el medico sean obligatorios
      if (this.data_seguimiento.paciente == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "El paciente es obligatorio",
          life: 3000,
        });
        this.enviado = false;
        return;
      }
      if (this.data_seguimiento.medico == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "El medico es obligatorio",
          life: 3000,
        });
        this.enviado = false;
        return;
      }

      if (this.seguimiento.id) {
        let seguimiento_enviar = {
          ...this.data_seguimiento,
          estado: this.data_seguimiento.estado.value ?? null,
          paciente_id: this.data_seguimiento.paciente.id,
          medico_id: this.data_seguimiento.medico.id,
          cama_id:
            this.data_seguimiento.cama_id != null
              ? this.data_seguimiento.cama_id
              : null,
        };
        this.seguimientoService
          .updatedSeguimiento(seguimiento_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      } else {
        let tmp = this.data_seguimiento;
        this.data_seguimiento = {
          ...this.data_seguimiento,
          estado: this.data_seguimiento.estado.value ?? null,
          paciente_id: this.data_seguimiento.paciente.id,
          medico_id: this.data_seguimiento.medico.id,
          cama_id:
            this.data_seguimiento.cama_id != null
              ? this.data_seguimiento.cama_id
              : null,
        };
        this.seguimientoService
          .sendSeguimientoNuevo(this.data_seguimiento)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_seguimiento = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });

              this.$emit("actualizarListado", data.seguimiento);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    seguimiento(val) {
      this.data_seguimiento = val;
    },
    //cuando selecciono mi fecha de alta, le sumo 15 dias habiles y se lo asigno a la fecha de retiro de puntos
    "data_seguimiento.fecha_alta": function (val) {
      if (val) {
        let fecha = new Date(val);
        let dias = 12;
        let dias_habiles = 0;
        while (dias_habiles < dias) {
          fecha.setDate(fecha.getDate() + 1);
          if (fecha.getDay() != 0 && fecha.getDay() != 6) {
            dias_habiles++;
          }
        }
        this.data_seguimiento.fecha_retiro_puntos = fecha;
        //tambien el estado hago que se selecione el de retiro de puntos
        this.data_seguimiento.estado = this.estados[3];
      }
    },
  },
};
</script>
  <style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>  