import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class SeguimientoService {
    async getSeguimientos(parametros) {
        const queryParams = parametros
            ? Object.keys(parametros)
                .map(
                    (k) =>
                        encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
                )
                .join("&")
            : "";
        const seguimientos = await fetchWrapper.get(
            `${ruta}/seguimiento?` + queryParams
        );
        return seguimientos;
    }

    async sendSeguimientoNuevo(seguimiento) {
        return await fetchWrapper.post(`${ruta}/seguimiento`, seguimiento);
    }
    async updatedSeguimiento(seguimiento) {
        return await fetchWrapper.put(`${ruta}/seguimiento/` + seguimiento.id, seguimiento);
    }

    async datos_necesarios_seguimiento() {
        return await fetchWrapper.post(`${ruta}/seguimiento/datos-necesarios`);
    }
}